import Link from "next/link";
import React, { useEffect, useState, useRef, Children } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Confetti from "@components/atoms/Confetti";
import { CONSTANTS, trackButtonFocus } from "@util/utils.js";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "@styles/cssinjs/helpers/variables.js";
export default function Header({ data, headerClassNames }) {
  const router = useRouter();
  const path = router.pathname;
  const [userDeviceInfo, setUserDeviceInfo] = useState("");
  const headerRef = useRef(null);
  const ToggleRef = useRef(null);
  const HandleToggleMenuClick = () => {
    headerRef.current.classList.toggle("menu-is-open");
    ToggleRef.current.classList.toggle("is-active");
  };
  const appMode = process.env.APP_MODE;
  const startNowLink = data?.otherItems?.startnow;
  const signupLink =
    appMode === "live"
      ? startNowLink?.href.live
      : startNowLink?.href.staging || startNowLink?.href;

  const DetectDevice = () => {
    const userAgent =
      typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
    if (userAgent.includes("Android")) {
      setUserDeviceInfo("android");
    }
    if (
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad") ||
      userAgent.includes("Macintosh")
    ) {
      setUserDeviceInfo("apple");
    }
  };
  const HeaderLogo = () => {
    return (
      <>
        <Head>
          <link rel="preload" as="image" href={data.logo.img.src} />
        </Head>
        <Link
          href="/"
          className="company-logo lg:mr-3"
          id={data.logo.id}
          aria-label={data.logo.img.alt}
        >
          <img
            width={data.logo.img.dimensions[0]}
            height={data.logo.img.dimensions[1]}
            src={data.logo.img.src}
            alt={data.logo.img.alt}
          />
          <style jsx>{`
            .company-logo {
              width: 156px;
            }
            .company-logo img {
              width: 100%;
            }
            @media (max-width: ${viewport.tablet.sm}) {
              .company-logo {
                width: auto !important;
              }
            }
            @media (max-width: ${viewport.tablet.sm}) {
              .company-logo img {
                width: 132px !important;
              }
            }
          `}</style>
        </Link>
      </>
    );
  };
  const SubNavPills = ({ data, dropdownName }) => {
    let pillClassNames;
    if (dropdownName == "industries") {
      pillClassNames = [
        "all-industries",
        "beauty",
        "education",
        "wellbeing",
        "professional-services",
        "healthcare",
        "trade",
        "creative",
      ];
    }
    return (
      <div className="section-links-container">
        {data.map((item, count) => {
          return (
            <Link
              key={count}
              href={item.href}
              prefetch={false}
              className={
                dropdownName === "industries" ? `${pillClassNames[count]}` : ``
              }
              {...(item.id ? { id: item.id } : {})}
            >
              {item.name}
            </Link>
          );
        })}
        <style global jsx>{`
          .all-industries:hover {
            border-color: ${colors.primitive.blue[300]} !important;
            color: ${colors.primitive.blue[300]} !important;
          }
          .beauty:hover {
            border-color: ${colors.primitive.yellow["default"]} !important;
            color: ${colors.primitive.yellow["default"]} !important;
          }
          .education:hover {
            border-color: ${colors.primitive.red[300]} !important;
            color: ${colors.primitive.red[300]} !important;
          }
          .wellbeing:hover {
            border-color: ${colors.primitive.red[200]} !important;
            color: ${colors.primitive.red[200]} !important;
          }
          .professional-services:hover {
            border-color: ${colors.primitive.green[400]} !important;
            color: ${colors.primitive.green[400]} !important;
          }
          .healthcare:hover {
            border-color: ${colors.derived.brand} !important;
            color: ${colors.derived.brand} !important;
          }
          .trade:hover {
            border-color: ${colors.primitive.violet[200]} !important;
            color: ${colors.primitive.violet[200]} !important;
          }
          .creative:hover {
            border-color: ${colors.primitive.grey[900]} !important;
            color: ${colors.primitive.grey[900]} !important;
          }
        `}</style>
      </div>
    );
  };
  const SubNavs = ({ data }) => {
    let attrs = {};
    return (
      <ul className="page-links-container">
        {data.map((item, count) => {
          attrs = {};
          if (item.hasOwnProperty("class") && item.class !== "") {
            attrs = {
              className: item.class,
            };
          }
          return (
            <li key={count} id={`${item.id ? item.id : ""}`} {...attrs}>
              <Link href={item.href} prefetch={false}>
                <i
                  dangerouslySetInnerHTML={{
                    __html: item.icon,
                  }}
                ></i>
                <span>{item.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const Li = ({ className, id, children, activeId, setActiveId, label }) => {
    const childrenArray = React.Children.toArray(children);
    const menuLink = childrenArray.slice(0, childrenArray.length / 2);
    const menuDropDown = childrenArray.slice(childrenArray.length / 2);

    return (
      <li
        className={`pos-rel ${className} ${activeId === id ? "active" : ""}`}
        id={id}
      >
        {menuLink}
        <button
          className="pos-abs"
          onClick={() => setActiveId(activeId === id ? "" : id)}
          tabIndex="0"
          aria-expanded={activeId === id ? "true" : "false"}
          aria-label={label ? `${label}-menu` : undefined}
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            height="15"
            width="15"
          >
            <path
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
        {menuDropDown}
        <style jsx>{`
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            outline-offset: 2px;
            opacity: 0;

            background: none;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
          }

          button:focus-visible {
            pointer-events: auto;
            opacity: 1;
          }
        `}</style>
      </li>
    );
  };

  const MainNavs = () => {
    const [activeId, setActiveId] = useState("");
    const link =
      data?.mainNav?.pricing?.href?.[appMode] || data?.mainNav?.pricing?.href;
    return (
      <ul className="main-nav-lg">
        <Li
          className="hover-link has-dropdown learn_list"
          id="header-nav-learn"
          activeId={activeId}
          setActiveId={setActiveId}
          label="learn"
        >
          <span className="no-border pointer" id={data.subNav.learn.id}>
            {data.subNav.learn.name}
          </span>
          <div className="dropdown--default lg:pb-2.5 lg:pl-4 lg:pr-2 lg:pt-3">
            <ul className="page-links-container">
              <li className="mb-0 mr-2 pr-1">
                <Link
                  href={data.mainNav.mission.href}
                  prefetch={false}
                  id={data.mainNav.mission.id}
                  className="ff-euclid fw-700"
                >
                  <span>{data.mainNav.mission.name}</span>
                </Link>
                <p className="text-xs text-light-grayish-blue mb-4">
                  {data.subNav.learn.mission.desc}
                </p>
                <a
                  href={data.subNav.learn.mission.btn.href}
                  className="btn-secondary btn-sm"
                  id={data.subNav.learn.mission.btn.id}
                >
                  <span>{data.subNav.learn.mission.btn.text}</span>
                </a>
              </li>

              <li>
                <Link
                  href={data.subNav.community[0].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.community[0].id}
                  className="ff-euclid fw-700 mb-0"
                >
                  <span>{data.subNav.community[0].name}</span>
                </Link>
                <Link
                  href={data.subNav.community[1].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.community[1].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.community[1].icon,
                    }}
                  ></i>
                  <span>{data.subNav.community[1].name}</span>
                </Link>

                <Link
                  href={data.subNav.community[2].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.community[2].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.community[2].icon,
                    }}
                  ></i>
                  <span>{data.subNav.community[2].name}</span>
                </Link>

                <Link
                  href={data.subNav.community[3].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.community[3].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.community[3].icon,
                    }}
                  ></i>
                  <span>{data.subNav.community[3].name}</span>
                </Link>
              </li>

              <li className="mb-0 ml-3 pr-0">
                <Link
                  href={data.subNav.resources[0].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.resources[0].id}
                  className="ff-euclid fw-700 mb-0"
                >
                  <span>{data.subNav.resources[0].name}</span>
                </Link>
                <Link
                  href={data.subNav.resources[1].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.resources[1].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.resources[1].icon,
                    }}
                  ></i>
                  <span>{data.subNav.resources[1].name}</span>
                </Link>
                <Link
                  href={data.subNav.resources[2].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.resources[2].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.resources[2].icon,
                    }}
                  ></i>
                  <span>{data.subNav.resources[2].name}</span>
                </Link>
                <Link
                  href={data.subNav.resources[3].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.resources[3].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.resources[3].icon,
                    }}
                  ></i>
                  <span>{data.subNav.resources[3].name}</span>
                </Link>
                <Link
                  href={data.subNav.resources[4].href}
                  prefetch={false}
                  rel="noopener"
                  id={data.subNav.resources[4].id}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: data.subNav.resources[4].icon,
                    }}
                  ></i>
                  <span>{data.subNav.resources[4].name}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Li>
        <Li
          className="hover-link has-dropdown ml-3 integration_list"
          id={data.mainNav.integrations.id}
          activeId={activeId}
          setActiveId={setActiveId}
          label="integrations"
        >
          <Link
            href={data.mainNav.integrations.href}
            prefetch={false}
            className="no-border pointer"
            role="link"
          >
            {data.mainNav.integrations.name}
          </Link>
          <div className="dropdown--default lg:py-4 lg:pr-4 lg:pl-3">
            <SubNavs data={data.subNav.integrations} />
            <SubNavPills
              data={data.subNavPills.integrations}
              dropdownName="integrations"
            />
          </div>
        </Li>
        <Li
          className="hover-link has-dropdown ml-3 feature_list"
          id={data.mainNav.features.id}
          activeId={activeId}
          setActiveId={setActiveId}
          label="features"
        >
          <Link
            href={data.mainNav.features.href}
            prefetch={false}
            className="no-border pointer"
            role="link"
          >
            {data.mainNav.features.name}
          </Link>
          <div className="dropdown--default lg:py-4 lg:pr-4 lg:pl-3">
            <SubNavs data={data.subNav.features} />
            <SubNavPills
              data={data.subNavPills.features}
              dropdownName="features"
            />
          </div>
        </Li>
        <Li
          className="hover-link has-dropdown ml-3 industries_list"
          id={data.mainNav.industries.id}
          activeId={activeId}
          setActiveId={setActiveId}
          label="industries"
        >
          <Link
            href={data.mainNav.industries.href}
            prefetch={false}
            className="no-border pointer"
            role="link"
          >
            {data.mainNav.industries.name}
          </Link>
          <div className="dropdown--default lg:py-4 lg:pr-4 lg:pl-3">
            <SubNavs data={data.subNav.industries} />
            <SubNavPills
              data={data.subNavPills.industries}
              dropdownName="industries"
            />
          </div>
        </Li>
        <Li
          className="hover-link has-dropdown ml-3  pricing_list"
          id={data.mainNav.pricing.id}
          activeId={activeId}
          setActiveId={setActiveId}
          label="pricing"
        >
          <a className="no-border pointer" href={link} role="link">
            {data.mainNav.pricing.name}
          </a>
          <div className="dropdown--default lg:pt-4 lg:pb-3 lg:px-4">
            <ul className="page-links-container">
              {data.subNav.pricing.map((item, count) => {
                const itemHref = item.href?.[appMode] || item.href;
                return (
                  <li id={item.id} key={count}>
                    <a href={itemHref}>
                      <p className="ff-euclid fw-700">{item.name}</p>
                      <p className="text-xs text-light-grayish-blue">
                        {item.desc}
                      </p>
                      <p className="text-xs">
                        <strong>{item.price}</strong> {item.plan}
                      </p>
                    </a>
                  </li>
                );
              })}
            </ul>
            <div>
              <a
                href={data.subNavPills.pricing[0].href}
                className="text-brandcolor"
                id={data.subNavPills.pricing[0].id}
              >
                {data.subNavPills.pricing[0].name}
              </a>
              <span>{data.subNavPills.pricing[1].name}</span>
            </div>
          </div>
        </Li>
        <style global jsx>{`
          .learn_list {
            margin-left: 116px;
          }
          .has-dropdown {
            position: relative;
          }
          .dropdown--default {
            width: 414px;
            position: absolute;
            left: -24px;
            margin-top: 7px;
            z-index: 10;
          }
          .dropdown--default::before {
            content: "";
            display: block;
            width: 100%;
            height: 10px;
            position: absolute;
            top: -10px;
            left: 0;
            opacity: 0;
          }
          .dropdown--default li {
            margin-bottom: 12px;
          }
          .dropdown--default li:last-child {
            margin-bottom: 0;
          }
          .main-nav-lg .has-dropdown:hover .dropdown--default,
          .main-nav-lg .has-dropdown.active .dropdown--default {
            display: block;
          }
          .header-dropdown-google-calendar a:hover svg path,
          .header-dropdown-wordpress a:hover svg path {
            fill: ${colors.primitive.blue[300]};
          }
          .header-dropdown-google-calendar a:hover svg path:first-child {
            fill: ${colors.primitive.white.default}!important;
          }
          @media (max-width: ${viewport.desktop.sm}) {
            .dropdown--default {
              display: none !important;
            }
          }
          @media (min-width: ${viewport.desktop.sm}) {
            .main-nav-lg {
              display: flex;
            }
            .main-nav-lg .dropdown--default {
              left: -28px;
            }
            .main-nav-lg .dropdown--default li {
              margin-bottom: 22px;
            }
            .main-nav-lg .feature_list .dropdown--default {
              left: -32px;
            }
            .main-nav-lg .pricing_list .dropdown--default {
              left: -360px;
            }
          }
        `}</style>
      </ul>
    );
  };
  const Contacts = () => {
    return (
      <div className="main-contacts no-border contact-num">
        <a href="tel:+1-800-749-4920">+1 (800) 749-4920</a>
        <style jsx>{`
          @media (min-width: ${viewport.tablet.sm}) {
            .contact-num {
              margin-left: auto;
            }
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .contact-num {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  };
  const CTAs = () => {
    return (
      <div className="buttons-wrap lg:ml-3">
        <a
          href={
            process.env.APP_MODE === "live"
              ? CONSTANTS.live.loginUrl
              : CONSTANTS.staging.loginUrl
          }
          className="link no-border lg:mr-3"
          id={data.otherItems.login.id}
        >
          {data.otherItems.login.text}
          <Confetti />
        </a>
        <Link
          href={signupLink}
          prefetch={false}
          className="btn-primary btn-sm"
          id={data.otherItems.startnow.id}
        >
          <span>{data.otherItems.startnow.text}</span>
        </Link>
        <style global jsx>{`
          .buttons-wrap {
            margin-left: auto;
          }
          .buttons-wrap > * {
            vertical-align: middle;
          }
          .buttons-wrap .btn-negative span {
            line-height: ${lineHeight[8]};
            margin-right: -2px;
          }
          .link {
            position: relative;
            display: inline-block;
            text-decoration: none;
            white-space: nowrap;
            color: ${colors.derived.primary};
            transition: transform 0.2s cubic-bezier(0.2, 1, 0.8, 1),
              color 0.4s linear;
          }
          .link:hover {
            transform: scale(1.05);
            color: ${colors.primitive.blue[300]};
          }
          .link span {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        `}</style>
      </div>
    );
  };
  const MobileMenuToggle = () => {
    return (
      <div className="mobile-menu lg:ml-0">
        <a
          href={
            process.env.APP_MODE === "live"
              ? CONSTANTS.live.loginUrl
              : CONSTANTS.staging.loginUrl
          }
          className="btn-negative lg:ml-4"
          id={`${data.otherItems.login.id}-mobile`}
        >
          {data.otherItems.login.text}
        </a>
        <span className="menu">Menu</span>
        <div className="toggle-btn-wrap" onClick={HandleToggleMenuClick}>
          <button
            className="toggle-btn"
            ref={ToggleRef}
            aria-label="Toggle menu icon"
          >
            <span className={`toggle-btn__bar`}></span>
          </button>
        </div>
        <style global jsx>{`
          .menu-is-open {
            overflow: hidden;
          }
          .main-header :global(.menu-visible) {
            visibility: visible;
            transform: scaleY(1);
            opacity: 1;
          }
          .toggle-btn-wrap {
            position: fixed;
            right: 8px;
            z-index: 100;
            width: 50px;
            height: 50px;
            top: 7px;
          }
          .toggle-btn {
            width: 24px;
            height: 18px;
            transform: rotate(0) scale(0.8);
            transform-origin: center;
            transition: transform 0.4s cubic-bezier(0.165, 0.85, 0.45, 1);
            cursor: pointer;
            position: fixed;
            right: 20px;
            top: 20px;
            margin: 0;
            z-index: 10;
            background-color: transparent;
            border: none;
          }
          .toggle-btn::after,
          .toggle-btn::before,
          .toggle-btn__bar {
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: ${colors.primitive.black[800]};
            border-radius: 18px;
          }
          .toggle-btn::after,
          .toggle-btn::before {
            content: "";
            transition: transform 0.4s cubic-bezier(0.165, 0.85, 0.45, 1) 0.4s;
          }
          .toggle-btn::before {
            top: 0;
            transform: translateY(0);
          }
          .toggle-btn::after {
            bottom: 0;
            transform: translateY(0);
          }
          .toggle-btn__bar {
            top: calc(50% - 1px);
            opacity: 1;
            transition: transform 0.4s cubic-bezier(0.165, 0.85, 0.45, 1),
              opacity 0s linear 0.4s;
          }
          .toggle-btn.is-active {
            transform: rotate(45deg);
            transition-delay: 0.4s;
          }
          .toggle-btn.is-active::after,
          .toggle-btn.is-active::before {
            transition-delay: 0s;
          }
          .toggle-btn.is-active::before {
            transform: translateY(8px);
          }
          .toggle-btn.is-active::after {
            transform: translateY(-8px);
          }
          .toggle-btn.is-active .toggle-btn__bar {
            opacity: 1;
            transform: rotate(-90deg);
            transition-delay: 0.4s, 0.4s;
          }
          @media (max-width: ${viewport.desktop.sm}) {
            .mobile-menu {
              margin-bottom: 1px;
            }
            .mobile-menu > * {
              vertical-align: middle;
            }
            .mobile-menu .btn-negative {
              margin: 0px;
            }
            .toggle-btn {
              right: 82px;
              top: 30px;
            }
          }
          @media (width: 1200px) {
            .toggle-btn {
              right: 20px;
              top: 30px;
            }
          }
          .mobile-menu .menu {
            display: none;
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .toggle-btn {
              position: absolute;
              transform: translateY(-40%);
              right: 77px;
              top: 32px;
            }
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .toggle-btn {
              right: 10px;
              top: 20px;
            }
          }
        `}</style>
      </div>
    );
  };
  const MobileMenu = () => {
    const AndroidAppCTA = () => {
      return (
        <a href={data.otherItems.andriodApp.href} className="btn-appstore">
          <img
            loading="lazy"
            width="130"
            height="40"
            src={data.otherItems.andriodApp.img.src}
            alt={data.otherItems.andriodApp.img.alt}
          />
        </a>
      );
    };
    const IosAppCTA = () => {
      return (
        <a href={data.otherItems.iosApp.href} className="btn-appstore">
          <img
            loading="lazy"
            width="130"
            height="40"
            src={data.otherItems.iosApp.img.src}
            alt={data.otherItems.iosApp.img.alt}
          />
        </a>
      );
    };
    const MobileMenuNav = () => {
      return (
        <ul className="main-nav-md">
          <li id={`${data.mainNav.mission.id}-mobile-view`}>
            {path === "/our-mission" ? (
              <a href={data.mainNav.mission.href}>
                {data.mainNav.mission.name}
              </a>
            ) : (
              <Link href={data.mainNav.mission.href} prefetch={false}>
                {data.mainNav.mission.name}
              </Link>
            )}
          </li>
          <li id={`${data.mainNav.integrations.id}-mobile-view`}>
            {path === "/integrations" ? (
              <a href={data.mainNav.integrations.href}>
                {data.mainNav.integrations.name}
              </a>
            ) : (
              <Link href={data.mainNav.integrations.href} prefetch={false}>
                {data.mainNav.integrations.name}
              </Link>
            )}
          </li>
          <li id={`${data.mainNav.features.id}-mobile-view`}>
            {path === "/features" ? (
              <a href={data.mainNav.features.href}>
                {data.mainNav.features.name}
              </a>
            ) : (
              <Link href={data.mainNav.features.href} prefetch={false}>
                {data.mainNav.features.name}
              </Link>
            )}
          </li>
          <li id={`${data.mainNav.industries.id}-mobile-view`}>
            {path === "/industries" ? (
              <a href={data.mainNav.industries.href}>
                {data.mainNav.industries.name}
              </a>
            ) : (
              <Link href={data.mainNav.industries.href} prefetch={false}>
                {data.mainNav.industries.name}
              </Link>
            )}
          </li>
          <li id={`${data.mainNav.pricing.id}-mobile-view`}>
            <a href={data.mainNav.pricing.href}>{data.mainNav.pricing.name}</a>
          </li>
          <style global jsx>{`
            .menu-is-open .mobile-menu-wrap .main-nav-md > li {
              opacity: 1;
            }
            @media (max-width: ${viewport.tablet.sm}) {
              .menu-is-open .mobile-menu-wrap .main-nav-md > li:last-child {
                margin-bottom: 0px;
              }
            }
            @media (max-width: ${viewport.desktop.sm}) {
              .mobile-menu-wrap .main-nav-md {
                display: inline-block;
                font-size: ${font.size.md[2]};
                line-height: ${lineHeight[7]};
                color: ${colors.primitive.black[100]};
                width: 100%;
              }
              .main-nav-md > * {
                margin-bottom: 24px;
              }
            }
          `}</style>
        </ul>
      );
    };

    const MobileMenuCTAs = () => {
      return (
        <div className="main-btns md:mt-5">
          <Link
            href={signupLink}
            prefetch={false}
            className="btn-primary md:px-0 mb-3"
            id={data.otherItems.startnowMobile.id}
            onFocus={trackButtonFocus(data.otherItems.startnowMobile.id)}
          >
            <span>{data.otherItems.startnowMobile.text}</span>
          </Link>
          <a
            href={
              process.env.APP_MODE === "live"
                ? CONSTANTS.live.loginUrl
                : CONSTANTS.staging.loginUrl
            }
            className="btn-primary md:px-0"
            id={`${data.otherItems.login.id}-mobile-cta`}
          >
            <span>{data.otherItems.login.text}</span>
          </a>
          {userDeviceInfo === "android" ? <AndroidAppCTA /> : <IosAppCTA />}
          <style global jsx>{`
            .menu-is-open .mobile-menu-wrap .main-btns > a {
              animation: navbarItemsAnimation 0.2s linear forwards;
            }
            .menu-is-open .mobile-menu-wrap .main-btns > a:nth-child(1) {
              animation-delay: 0.9s;
            }
            .menu-is-open .mobile-menu-wrap .main-btns > a:nth-child(2) {
              animation-delay: 1.05s;
            }
            .menu-is-open .mobile-menu-wrap .main-btns > a:nth-child(3) {
              animation-delay: 1.2s;
            }
            @media (max-width: ${viewport.desktop.sm}) {
              .main-btns [class*="btn-"] {
                width: 130px;
                min-width: initial;
              }
              .main-btns img {
                width: 100%;
              }
              .main-btns .btn-primary {
                padding: 8px 32px;
              }
              .main-btns .btn-primary span {
                font-size: ${font.size.sm[4]};
              }
              .main-btns .btn-appstore,
              .main-btns .contact-number,
              .main-nav-md .btn-appstore,
              .main-nav-md .contact-number {
                margin-top: 24px;
              }
            }
          `}</style>
        </div>
      );
    };

    const MobileMenuContacts = () => {
      return (
        <div className="main-contacts">
          <a href="tel:+1-800-749-4920">+1 (800) 749-4920</a>
          <a className="contact-email" href="mailto:help@setmore.com">
            help@setmore.com
          </a>
          <style global jsx>{`
            .menu-is-open .mobile-menu-wrap .main-contacts {
              margin-top: 48px;
            }
            .menu-is-open .mobile-menu-wrap .main-contacts > a {
              animation: navbarItemsAnimation 0.2s linear forwards;
            }
            .menu-is-open .mobile-menu-wrap .main-contacts > a:nth-child(1) {
              animation-delay: 1.2s;
            }
            .menu-is-open .mobile-menu-wrap .main-contacts > a:nth-child(2) {
              animation-delay: 1.35s;
            }
            .menu-is-open .mobile-menu-wrap .main-contacts .contact-email {
              margin-top: 24px;
            }
            @media (max-width: ${viewport.desktop.sm}) {
              .main-contacts > * {
                line-height: ${lineHeight[6]};
              }
            }
          `}</style>
        </div>
      );
    };
    return (
      <div className={`mobile-menu-wrap`}>
        <nav>
          <MobileMenuNav />
          <MobileMenuCTAs />
          <MobileMenuContacts />
        </nav>
        <style global jsx>{`
          .mobile-menu-wrap {
            position: fixed;
            width: 100vw;
            visibility: hidden;
          }
          .mobile-menu-wrap nav {
            opacity: 0;
          }
          .menu-is-open .mobile-menu-wrap {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            overflow: auto;
            visibility: visible;
            transform: scaleY(1);
            opacity: 1;
          }
          .menu-is-open .mobile-menu-wrap.add-scroll {
            overflow: scroll;
          }
          .menu-is-open .mobile-menu-wrap nav {
            animation: navOpenAnimation 0.6s linear;
            margin-bottom: 80px;
            opacity: 1;
          }
          .menu-is-open .mobile-menu-wrap nav .industries {
            display: inline-block;
          }
          @media (max-width: ${viewport.desktop.sm}) {
            .mobile-menu-wrap .logo-wrap {
              margin: 0 20px;
              display: flex;
              padding: 16px 0 16px;
            }
            .mobile-menu-wrap .logo-wrap img {
              width: 85%;
            }
            .mobile-menu-wrap > nav {
              padding: 48px 0 48px 40px;
              border-radius: 8px;
              box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
                0 1px 4px 0 rgba(22, 45, 61, 0.1);
              background-color: ${colors.primitive.white.default};
              margin-top: 8px;
              margin-left: 12px;
              width: calc(100% - 24px);
              display: block;
            }
            .mobile-menu-wrap nav > ul {
              width: 100%;
            }
            .mobile-menu-wrap .main-btns > *,
            .mobile-menu-wrap .main-contacts > *,
            .mobile-menu-wrap .main-nav-md > * {
              display: block;
            }
            .mobile-menu-wrap .main-btns > * a,
            .mobile-menu-wrap .main-contacts > * a,
            .mobile-menu-wrap .main-nav-md > * a {
              display: inline-block;
              width: 100%;
            }
          }
        `}</style>
      </div>
    );
  };

  const StickyHeader = () => {
    const header = document.querySelector("header");
    function makeItFixed() {
      if (window.scrollY > 20) {
        header.classList.add("is-fixed");
      } else {
        header.classList.remove("is-fixed");
      }
    }
    window.addEventListener("scroll", () => {
      makeItFixed();
    });

    window.addEventListener("load", () => {
      makeItFixed();
    });
  };

  useEffect(() => {
    DetectDevice();
    StickyHeader();
  });

  return (
    <section ref={headerRef}>
      <header className={`is-variant ${headerClassNames}`}>
        <nav className={`main-header container home_indicator header-v2`}>
          <HeaderLogo />
          <MainNavs />
          <Contacts />
          <CTAs />
          <MobileMenuToggle />
          <MobileMenu />
        </nav>
        <style global jsx>{`
          .is-variant {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            z-index: 10;
            transition: 0.5s box-shadow linear;
          }
          .is-variant.is-fixed {
            position: fixed;
            top: 0;
            background-color: ${colors.primitive.white.default};
            box-shadow: 0 2px 4px 0 ${colors.derived.shadow};
          }
          .main-header {
            display: flex;
            align-items: center;
            padding: 28px 0;
          }
          .main-header > .main-nav a {
            display: inline-block;
            line-height: ${lineHeight[6]};
            vertical-align: middle;
          }
          .header-v2 {
            color: ${colors.primitive.grey[800]};
          }
          .menu-is-open header .company-logo,
          .menu-is-open header .mobile-menu > a {
            visibility: hidden;
          }
          .menu-is-open header .company-logo,
          .menu-is-open header .main-btns > a,
          .menu-is-open header .main-contacts > a,
          .menu-is-open header .main-nav-md > li,
          .menu-is-open header .mobile-menu > a {
            opacity: 0;
          }
          @media (min-width: ${viewport.desktop.sm}) {
            .menu-open,
            .mobile-menu,
            .mobile-menu-wrap {
              display: none;
            }
          }
          @media (max-width: ${viewport.desktop.sm}) {
            .menu-open,
            .mobile-menu,
            .mobile-menu-wrap {
              display: block;
            }
            .main-header {
              padding: 18px 0;
            }
            .main-header .buttons-wrap,
            .main-header > .main-nav-lg {
              display: none;
            }
            .icon-close,
            .icon-toggle {
              margin-left: 4px;
            }
            .main-header {
              padding: 27px 0;
              margin: 0 84px;
            }
            .menu-close,
            .mobile-menu {
              margin-left: auto;
              margin-right: 65px;
            }
          }
          @keyframes navOpenAnimation {
            from {
              max-height: 300px;
            }
            to {
              max-height: 100%;
            }
          }
          @keyframes navbarItemsAnimation {
            from {
              transform: translateY(-10px);
              opacity: 0;
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .main-header {
              margin: 0 20px !important;
              padding: 18px 0 !important;
            }
            .menu-close,
            .mobile-menu {
              margin-right: 45px;
            }
          }
        `}</style>
      </header>
    </section>
  );
}
