// @Framework Dependencies
import Head from "next/head";
import { CONSTANTS } from "@util/utils";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import Theme from "../components/Theme";
import CustomEmailInput from "../components/organisms/common/forms/CustomEmailInput";
import Video from "../components/atoms/common/Video";
import CarouselFullWidth from "../components/organisms/common/CarouselFullWidth";
import ResponsiveImage from "../components/atoms/image/ResponsiveImage";
import AdaptiveCriticalImage from "../components/atoms/AdaptiveCriticalImage";
import CTA from "../components/organisms/common/CTA";
import FAQ from "../components/organisms/common/FAQ";
import Schema from "../components/organisms/common/Schema";
import CutoutFeaturedImage from "../components/organisms/common/CutoutFeaturedImage";
import CTAWithIntegrations from "../components/organisms/common/CTAWithIntegrations";
import SignUpCTA from "../components/organisms/common/SignupCTA";
import PromoBanner from "../components/organisms/common/PromoBanner";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../styles/cssinjs/helpers/variables.js";
import { useVideoAutoPlay } from "@components/atoms/common/useVideoAutoPlay";

const VideoSchema = dynamic(() =>
  import("../components/organisms/common/VideoSchema")
);
const FeaturedImage = dynamic(() =>
  import("../components/organisms/common/FeaturedImage")
);
const FeaturesGrid = dynamic(() =>
  import("../components/organisms/common/FeaturesGrid")
);
const PricingPlanWithTitle = dynamic(() =>
  import("../components/organisms/common/PricingPlanWithTitle")
);
const TpWidgetMicro = dynamic(() =>
  import("../components/organisms/common/TpWidgetMicro")
);
const TpWidgetSlider = dynamic(() =>
  import("../components/organisms/common/TpWidgetSlider")
);
const TpWidget = dynamic(() => import("../components/atoms/document/TpWidget"));
const AppStorage = dynamic(() => import("../components/AppStorage"));

export const Hero = ({ data, lang }) => {
  const appMode = process.env.APP_MODE;
  const link = data?.form?.submit?.href?.[appMode] || data?.form?.submit?.href;
  const videoRef = useVideoAutoPlay(0.6);
  return (
    <>
      <Head>
        <link
          rel="preload"
          href={data.video.thumbnail}
          as="video"
          type="video/mp4"
        />
        <link rel="preload" href={data.video.preview} as="image" />
      </Head>
      <section className="container grid--default has-align-top hero--default lg:mb-15 md:mb-5">
        <article className="text-left lg:maxw-md md:mb-5">
          <h1
            className="text-xl lg:pt-5"
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></h1>
          <p dangerouslySetInnerHTML={{ __html: data.desc }} />
          <CustomEmailInput
            id="indexEmailHeaderForm"
            action={link}
            data={data.form}
            socialSignUpData={data.socialSignup}
            socialSignupContainerClass="mt-2"
            lang={lang}
            submitButtonId="start-free-button-hero-fold"
          />
          <TpWidgetMicro className="trustpilot-widget-micro lg:mt-5 md:mt-6 " />
        </article>{" "}
        <figure className="layered-images-wrap pos-rel lg:maxw-lg">
          <video
            ref={videoRef}
            muted
            loop
            poster={data.video.preview}
            preload="auto"
            className="w-100"
            playsInline
          >
            <source src={data.video.thumbnail} type="video/mp4" />
          </video>
          <picture>
            <AdaptiveCriticalImage
              src={data.image.src}
              alt={data.image.alt}
              mobile={data.image.prefixes.mobile}
              tablet={data.image.prefixes.tablet}
              dpi={data.image.prefixes.dpi}
              className="md:w-100 fg-layer"
            />
          </picture>
        </figure>
      </section>
    </>
  );
};
const FeaturesGridSection = ({ data, isIconInline }) => {
  return (
    <section className="md:pb-10 bg-light-blue-grey features-grid-section">
      <FeaturesGrid
        data={data.list}
        colCount={data.colCount}
        isIconsInline={isIconInline}
      />
      <style jsx global>{`
        .icon-title-wrap figure {
          width: 28px;
        }
        .card--default .icon-title-wrap figure {
          width: 28px;
        }
      `}</style>
    </section>
  );
};
const AppDownload = ({ data }) => {
  return (
    <section className="home-app app-download pos-rel lg:mt-15" id="home-app">
      <div className="d-fx container">
        <article className="lg:maxw-md">
          <h2
            className="text-lg lg:mb-3 md:mb-2 md:mt-5"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <p
            className="lg:mb-5 md:mb-5"
            dangerouslySetInnerHTML={{ __html: data.desc[0] }}
          />
          <div className="fx lg:mb-5 md:mb-2 device-btn-wrap grid--default fs-o lg:maxw-mds appdownload-mobile">
            <a
              href={data.downloadAppSection[0].href}
              className="w-auto btn-secondary lg:pl-2.5 lg:pr-2.5 md:pl-2.5 md:pr-2.5 lg:mr-3 md:mr-2"
            >
              <i
                dangerouslySetInnerHTML={{
                  __html: data.downloadAppSection[0].icon,
                }}
              ></i>
              <span>{data.downloadAppSection[0].text}</span>
            </a>{" "}
            <a
              href={data.downloadAppSection[1].href}
              className="w-auto btn-secondary lg:pl-2.5 lg:pr-2.5 md:pl-2.5 md:pr-5"
            >
              <i
                dangerouslySetInnerHTML={{
                  __html: data.downloadAppSection[1].icon,
                }}
              ></i>
              <span>{data.downloadAppSection[1].text}</span>
            </a>
          </div>
          <div className="fx lg:mb-5 md:mb-2 device-btn-wrap grid--default fs-o lg:maxw-mds appdownload-desktop">
            <a
              href={data.downloadAppSection[2].href}
              className="w-auto btn-secondary lg:pl-2.5 lg:pr-2.5 md:pl-2.5 md:pr-2.5 lg:mr-3 md:mr-2"
            >
              <i
                dangerouslySetInnerHTML={{
                  __html: data.downloadAppSection[2].icon,
                }}
              ></i>
              <span>{data.downloadAppSection[2].text}</span>
            </a>{" "}
            <a
              href={data.downloadAppSection[3].href}
              className="w-auto btn-secondary lg:pl-2.5 lg:pr-2.5 md:pl-2.5 md:pr-5"
            >
              <i
                dangerouslySetInnerHTML={{
                  __html: data.downloadAppSection[3].icon,
                }}
              ></i>
              <span>{data.downloadAppSection[3].text}</span>
            </a>
          </div>
          <p
            className="text-xs appdownload-mobile"
            dangerouslySetInnerHTML={{ __html: data.desc[1] }}
          />
          <p
            className="text-xs appdownload-desktop"
            dangerouslySetInnerHTML={{ __html: data.desc[2] }}
          />
        </article>
        <ResponsiveImage
          src={data.image.src}
          alt={data.image.alt}
          mobile={data.image.prefixes.mobile}
          dpi={data.image.prefixes.dpi}
          wrapClass="highlight-img"
        />
      </div>
    </section>
  );
};

const VideoSection = ({ data, toggleVideo, isVideoModalVisible, videoId }) => {
  return (
    <section className="container lg:mt-19 lg:mb-20 md:mt-10 md:mb-10 video-section">
      <div className="grid--default has-align-middle md:fx md:fx--w">
        <Video
          data={data.video}
          classNames="featured-video md:w-100"
          toggleVideo={(id) => {
            toggleVideo(id);
          }}
          isVideoModalVisible={isVideoModalVisible}
          videoId={videoId}
        />
        <article className="text-left lg:maxw-md fx--o-2">
          <h2 className="text-lg lg:mb-3 md:mb-2 md:mt-5">{data.title}</h2>
          <p>{data.desc}</p>
        </article>{" "}
      </div>
    </section>
  );
};

const FeedbackSection = ({
  data,
  toggleVideo,
  isVideoModalVisible,
  videoId,
}) => {
  return (
    <section className="home-masonry bg-offset lg:mb-20">
      <h2 className="people-love-setmore container text-xl text-center lg:mb-9 md:mb-5">
        <span>{data.masonry.title}</span>{" "}
        <span className="md:d-block lg:ml-1 lg:mr-1">❤️</span>{" "}
        <span>Setmore</span>
      </h2>
      <TpWidgetSlider />
      <CarouselFullWidth
        data={data.carouselItems}
        toggleVideo={(id) => {
          toggleVideo(id);
        }}
        isVideoModalVisible={isVideoModalVisible}
        videoId={videoId}
        isShowPreview={true}
      />
      <div className="testimonial-container" tabIndex="-1"></div>
    </section>
  );
};

const CTAGrey = ({ data }) => {
  return (
    <section className="book-section bg-dark-grey md:pt-5 lg:pb-20  md:pr-0 md:pl-0">
      <div className="container book-feed bg-almost-black lg:py-10">
        <article className="lg:text-left md:text-center lg:maxw-sm">
          <h2
            className="text-lg lg:mb-3 md:mb-2"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <p>{data.desc}</p>
        </article>{" "}
        <ResponsiveImage
          dpi={data.image.prefixes.dpi}
          src={data.image.src}
          alt={data.image.alt}
          hasMobileImg={false}
        />
        <style jsx>{`
          h2,
          .dot {
            color: ${colors.primitive.grey[700]} !important;
          }
          p {
            color: ${colors.primitive.grey[600]};
          }
        `}</style>
      </div>
    </section>
  );
};

const Steps = ({ data }) => {
  return (
    <section className="lg:pb-10 lg:pt-20 md:mp-10 lg:mb-20 md:mb-10 bg-light-blue-grey booking-steps">
      <article className="container">
        <h2 className="text-xl mb-5">{data.title}</h2>
        <ul className="card--default card--feature has-three-col lg:mb-5 md:mb-3">
          {data.steps.map((step, index) => {
            return (
              <li key={index} className="steps">
                <p
                  dangerouslySetInnerHTML={{
                    __html: step.desc,
                  }}
                  data-step={index + 1}
                  className={`lg:mb-5 steps-para`}
                ></p>
                <ResponsiveImage
                  src={step.illustration}
                  alt={step.alt}
                  dpi={"@2x"}
                  mobile={"crop"}
                  imgClass={``}
                  wrapClass={`booking-step steps-img`}
                  hasMobileImg={false}
                  hasTabletImg={false}
                />
              </li>
            );
          })}
        </ul>
        {data.hasOwnProperty("link") && (
          <p>
            <a href={data.link.href} className="text-brandcolor">
              {data.link.text}
            </a>
          </p>
        )}
      </article>
      <ResponsiveImage
        src={data.images.desktopMobile.src}
        alt={data.images.desktopMobile.alt}
        dpi={data.images.desktopMobile.prefixes.dpi}
        mobile={data.images.desktopMobile.prefixes.mobile}
        imgClass={`desktop-mobile-img`}
        wrapClass={`desktop-mobile-picture`}
        hasMobileImg={true}
        hasTabletImg={false}
        width={data.images.desktopMobile.dimensions[0]}
        height={data.images.desktopMobile.dimensions[1]}
        aria-hidden="true"
      />
      <ResponsiveImage
        src={data.images.tablet.src}
        alt={data.images.tablet.alt}
        dpi={data.images.tablet.prefixes.dpi}
        mobile={data.images.tablet.prefixes.mobile}
        imgClass={`tablet-img`}
        wrapClass={`tablet-picture tablet-show`}
        hasMobileImg={false}
        hasTabletImg={true}
        width={data.images.tablet.dimensions[0]}
        height={data.images.tablet.dimensions[1]}
        aria-hidden="true"
      />
    </section>
  );
};

const PillsSection = ({ data }) => {
  return (
    <section className="talkingAboutUsSection text-center md:mb-9">
      <h2 className="lg:mb-5 md:mb-3">{data.title}</h2>
      <div className="big-pill">
        {data.pills.map((icon, index) => {
          return (
            <div className="small-pill" key={index}>
              <svg width={icon.dimensions[0]} height={icon.dimensions[1]}>
                <use href={`/sprite.svg#${icon.svg}`}></use>
              </svg>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export async function getStaticProps({ locale }) {
  let allData = {};
  // Fetch data for respective pages
  // Fetch
  const headerResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/common/header.json`
  );
  const footerResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/common/footer.json`
  );
  const pricingResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/common/pricing.json`
  );
  const marketingResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/homepage/marketing.json`
  );
  const contentResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/homepage/content.json`
  );

  // Catch Error
  if (!marketingResponse.ok || !contentResponse.ok) {
    return {
      notFound: true,
    };
  }

  // Convert
  allData.header = await headerResponse.json();
  allData.footer = await footerResponse.json();
  allData.marketing = await marketingResponse.json();
  allData.content = await contentResponse.json();
  allData.pricing = await pricingResponse.json();

  // Wrap
  const header = {
    header: allData.header,
  };
  const footer = {
    footer: allData.footer,
  };
  const pricing = {
    pricing: allData.pricing,
  };

  // Combine all data
  const data = await {
    ...header,
    ...footer,
    ...pricing,
    ...allData.marketing,
    ...allData.content,
  };

  // Return to the component
  return {
    props: {
      data,
      lang: locale,
    },
  };
}

export default function Index({ data, lang }) {
  const { seo, header, footer, pricing, content } = data;

  const toggleVideo = (id) => {
    window.closeModal = () => {
      const testimonialModal = document.getElementById("testimonial-modal");
      testimonialModal.remove();
    };
    const modalPlayer = `
    <div class="testimonial-modal" onclick="closeModal()" id="testimonial-modal">
    <div class="video-content">
      <div class="video-cont is-played">
        <iframe
          id="videoUtube"
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="YouTube video player"
          width="960"
          height="539"
          src="https://www.youtube.com/embed/${id}?autoplay=1&amp;controls=1&amp;showinfo=0&amp;modestbranding=1&amp;loop=1&amp;fs=0&amp;cc_load_policy=0&amp;iv_load_policy=3&amp;autohide=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.setmore.com&amp;widgetid=2"
          data-gtm-yt-inspected-1_19="true"
        ></iframe>
      </div>
      <div class="text-center">
        <a
          href=${
            process.env.APP_MODE === "live"
              ? `${CONSTANTS.live.universalSignupUrl}/start-now`
              : `${CONSTANTS.staging.universalSignupUrl}/start-now`
          }
          target="_blank"
          class="btn-primary lg:mt-5 md:mt-2.5"
          id="button-hero-cta"
        >
          <span>Get started</span>
        </a>
      </div>
    </div>
    <button id="closeBtn">
      Close
      <svg  width="24" height="24" className="ml-1">
      <use href="/sprite.svg#modal-close"></use>
    </svg>
    </button>
    </div>`;
    document.body.querySelector(".testimonial-container").innerHTML =
      modalPlayer;
    document.body.querySelector(".testimonial-container").focus();
  };

  const handleVideoCarousel = () => {
    const carousel = {
      container: document.querySelector(".carousel"),
      item: Array.prototype.slice.call(
        document.querySelectorAll(".carousel-item")
      ),
    };

    const moveCarousel = () => {
      if (window.innerWidth > 768) {
        carousel.container.style.transform = "translate(-660px)";
      } else {
        carousel.container.style.transform = "translate(-590px)";
      }
    };

    const playFocusedCardVideo = () => {
      carousel.container.children[1]?.children[0]?.children[0]?.children[0].pause();
      carousel.container.children[2]?.children[0]?.children[0]?.children[0].pause();
      carousel.container.appendChild(carousel.container.firstElementChild);
      carousel.container.style.transition = "none";
      if (window.innerWidth > 768) {
        carousel.container.style.transform = "translate(-260px)";
        carousel.container.children[2]?.children[0]?.children[0]?.children[0].play();
      } else {
        carousel.container.style.transform = "translate(-275px)";
        carousel.container.children[1]?.children[0]?.children[0]?.children[0].play();
      }
      setTimeout(function () {
        carousel.container.style.transition = "all 0.8s";
      });
    };
    setInterval(moveCarousel, 8000);
    carousel.container.addEventListener("transitionend", function (e) {
      if (e.target != this) {
        return;
      } else {
        playFocusedCardVideo();
      }
    });
  };

  useEffect(() => {
    handleVideoCarousel();
  }, []);

  return (
    <Theme
      header={header}
      footer={footer}
      seo={seo}
      className="homepage page--home pricing-layout industry-page-with-trust-pilot page-with-widget"
    >
      <AppStorage pageName="v2 setmore homepage indexv2" />
      <PromoBanner />
      <Hero data={content.hero} lang={lang} />
      <CutoutFeaturedImage
        data={content.cutoutFeaturedImage}
        className={"bg-light-blue-grey"}
        imgClassName={"imgholder-septenary"}
      />
      <FeaturesGridSection data={content.featuresGrid} isIconInline={true} />
      <Steps data={content.stepsSection} className="lg:mt-10" />
      <PillsSection data={content.pillsSection} />
      <AppDownload data={content.appDownload} />
      <VideoSection data={content.videoSection} />
      <CTAWithIntegrations
        data={content.ctaWithIntegrations}
        className="lg:pb-20 md:pb-10"
      />
      <section className="lg:mb-20 md:mb-10 featured-img-section">
        {content.featuredImageSections.map((item, index) => {
          return (
            <FeaturedImage
              data={item}
              key={index}
              imageAlign={item.image.align}
              className={`featured-img-${index}`}
            />
          );
        })}
      </section>
      <CTA
        data={content.cta}
        className="lg:pb-20 md:pb-10 cta-halflength"
        colorScheme={"grey-white-blue"}
      />
      <FeedbackSection
        data={content.blogCarousel}
        toggleVideo={(id) => {
          toggleVideo(id);
        }}
      />
      <PricingPlanWithTitle
        heading={content.pricingPlan}
        pricing={pricing}
        initialPlanType="monthly-billing-plan"
        className="pricing-wrap lg:pb-20 bg-offset md:pt-10 md:pb-10 bg-light-blue-grey"
      />
      <FAQ
        data={content.faqs}
        className="lg:pb-15 md:pb-10 bg-light-blue-grey"
      />
      <SignUpCTA data={content.signUpCTA} />
      <CTAGrey data={content.ctaSection} />
      <style jsx global>{`
        .chatsupport-button {
          visibility: hidden !important;
        }
        .cta-contrast.has-image article {
          max-width: 560px;
        }
        @media (min-width: ${viewport.desktop.sm}) {
          .cta-contrast.has-image picture {
            right: 100px;
          }
        }
        .hero--default .fg-layer {
          bottom: -15px;
        }
        .video-highlight {
          border-radius: 8px;
          overflow: hidden;
          height: auto !important;
        }
        .featured-video {
          width: 50%;
        }
        .carousel .video-highlight {
          width: calc(100% - 8px);
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-top: 3px;
        }
        .bg-offset:after {
          background-color: ${colors.primitive.grey[600]};
        }
        .jsx-btn-primary {
          line-height: 1;
        }
        @media (min-width: ${viewport.tablet.sm}) and (max-width: ${viewport
            .desktop.sm}) {
          .use-setmore-wrap picture {
            min-width: 157px !important;
          }
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .use-setmore-wrap :global(picture) :global(img) {
            margin-top: -50px;
          }
          .pricing-wrap.bg-offset::after {
            height: 20% !important;
            top: 0;
          }
        }
      `}</style>
      <Head>
        <Schema data={content.faqs.list} />
        <VideoSchema
          data={[content.videoSection, ...content.blogCarousel.carouselItems]}
        />
      </Head>
      <TpWidget />
    </Theme>
  );
}
